// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCQQYAJNL3PCrdR2yoyW35hiRfOSNiO0SA",
  authDomain: "yvom3rd.firebaseapp.com",
  databaseURL: "https://yvom3rd-default-rtdb.firebaseio.com",
  projectId: "yvom3rd",
  storageBucket: "yvom3rd.appspot.com",
  messagingSenderId: "165500616975",
  appId: "1:165500616975:web:8460fa8897eb3578913e7a",
  measurementId: "G-6Q71DPR7LD"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };